import * as React from 'react';
import { Button, Form, InputGroup } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../App.css';
const SignUp = () => {
    const [consent, setConsent] = React.useState(false);
    const [mobile, setMobile] = React.useState('');
    const [password, setPassword] = React.useState("");
    const [passwordAgain, setPasswordAgain] = React.useState("");
    const [passwordType, setPasswordType] = React.useState("password");
    const [passwordPlaceholder, setPasswordPlaceholder] = React.useState("Choose a secure password");
    const initialInputState = { firstName: "", lastName: "", pswd: "", email: "", mobile: "", address: "", city: "", state: "", zip: "" };
    const [registration, setRegistration] = React.useState(initialInputState);
    const { firstName, lastName, pswd, email, address, city, state, zip } = registration;
    const handleChange = (e) => {
        e.preventDefault();
        setRegistration({ ...registration, [e.target.name]: e.target.value });
    };
    const togglePassword = (e) => {
        e.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const handleConsent = () => {
        setConsent(!consent);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistration( ...registration, {"pswd": password });
        console.log(registration);
    };
    return (
        <div className="container-sm">
            <div className="row justify-content-center my-2">
                <div className="col-sm my-2">
                    <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={firstName}
                            name="firstName"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
                <div className="col-sm my-2">
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={lastName}
                            name="lastName"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-sm">
                    <Form.Group>
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={address}
                            name="address"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className='col-sm-7 my-1'>
                    <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={city}
                            name="city"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
                <div className='col-sm-3 my-1'>
                    <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" name="state" defaultValue={state} onChange={handleChange}>
                            <option value=""></option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='col-sm-2 my-1'>
                    <Form.Group>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={zip}
                            name="zip"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="row justify-content-center my-2" >
                <div className="col-sm ny-2">
                    <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={email}
                            name="email"
                            required
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>
                <div className="col-sm">
                    <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <PhoneInput
                            className="form-control"
                            name="mobile"
                            country="US"
                            value={mobile}
                            onChange={setMobile}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-sm">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                        <Form.Control onChange={e => setPassword(e.target.value)} type={passwordType} placeholder={passwordPlaceholder} />
                        <Button onClick={togglePassword} variant='outline-secondary'>
                            {
                                passwordType === "password" ?
                                    <i className="bi bi-eye-slash"></i> :
                                    <i className="bi bi-eye"></i>
                            }
                        </Button>
                    </InputGroup>
                </div>
                <div className='col-sm'>
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control onChange={e => setPasswordAgain(e.target.value)} type={passwordType} placeholder="Confirm Password" />
                        <Button onClick={togglePassword} variant='outline-secondary'>
                            {
                                passwordType === "password" ?
                                    <i className="bi bi-eye-slash"></i> :
                                    <i className="bi bi-eye"></i>
                            }
                        </Button>
                    </InputGroup>
                </div>
                <div className="row justify-content-center my-1">
                    <PasswordChecklist
                        rules={["minLength", "specialChar",
                            "number", "capital", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                    />
                </div>
            </div>
            <div className="d-flex flex-row mb-3 justify-content-evenly">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox" id="consentCheck"
                        checked={consent}
                        onChange={handleConsent}
                    />
                    <label className="form-check-label text-start" htmlFor="consentCheck">
                        I agree to Avni Healthcare's <a href="#!">Terms & Conditions and Privacy Policy</a>
                    </label>
                </div>
            </div>
            <div className='d-flex flex-row mb-3 justify-content-evenly'>
                <Button onClick={handleSubmit} variant="primary" type="submit">
                    Submit
                </Button>
            </div>
        </div>
    )
}
export default SignUp;

