import * as React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
const Services = () => {
    const [open, setOpen] = React.useState("");
    const handleListItemClick = (event, item) => {
        if (open === item) {
            item = "";
        }
        setOpen(item);
    };
    return (
        <div className='d-flex align-items-center flex-column justify-content-evenly'>
            <div>
                <h2>How it works...</h2>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>
            <div>
                <div>
                    <div className="btn btn-link text-success hangingindent" name="order" onClick={(event) => handleListItemClick(event, "order")} ><strong>1. Clinician Orders RPM</strong></div>
                    {
                        open === "order" &&
                        <div>
                            <p className='hangingindent'>a. During an in-office or telemedicine visit, clinician discusses a regimen of regular vitals,
                                e.g.,
                                blood pressure, monitoring by the patient and writes an order for RPM.</p>
                            <p className='hangingindent'>b. At check out, the medical assistant provides details about Avni and to expect a call from them.</p>
                            <p className='hangingindent'>c. Medical assistant transmits order to Avni.</p>
                        </div>
                    }
                </div>
                <div>
                    <div className="btn btn-link text-success hangingindent" name="provision" onClick={(event) => handleListItemClick(event, "provision")}> <strong>2. AVNI Helps Patient Setup RPM</strong></div>
                    {
                        open === "provision" &&
                        <div>
                            <p className='hangingindent'>a. Avni contacts patient and provides a schedule for shipment of the RPM equipment and
                                education.</p>
                            <p className='hangingindent'>b. Avni ships equipment and confirms status on delivery with patient.</p>
                            <p className='hangingindent'>c. At scheduled date and time, Avni meets with patient over video and educates patient to practice collecting vitals.</p>
                        </div>
                    }</div>
                <div>
                    <div className="btn btn-link text-success hangingindent" name="use" onClick={(event) => handleListItemClick(event, "use")}> <strong>3. Patient Uses RPM</strong></div>
                    {
                        open === "use" &&
                        <div>
                            <p className='hangingindent'>a. At prescribed intervals, patient uses RPM to collect data, which is securely transmitted to the
                                clinical system.</p>
                            <p className='hangingindent'>b. Patient may, at any time, review the collected data.</p>
                        </div>
                    }
                </div>
                <div>
                    <div className="btn btn-link text-success hangingindent" name="monitor" onClick={(event) => handleListItemClick(event, "monitor")} ><strong>4. Care Team Monitors Patient</strong></div>
                    {
                        open === "monitor" &&
                        <div>
                            <p className='hangingindent'>a. Monitors for anomalies and takes any required actions, e.g., contact patient.</p>
                            <p className='hangingindent'>b. During subsequent in-office or telemedicine visits, clinician reviews RPM data with patient.</p>
                            <p className='hangingindent'>c. Clinician may recommend additional monitoring, reducing or increasing frequency, or ending RPM.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Services;