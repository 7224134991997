import * as React from 'react';
import { Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import Support from './Support';
import Services from './Services';
const Home = () => {
    return (
        <div className="container-sm">
            <div className="d-flex align-items-center flex-column">
                <p className="text-center">We provide FDA-approved Remote Patient Monitoring (RPM) devices integrated into our secure data cloud.  We make it easy for patients to collect their vitals and share them with providers. <strong>We make connected-care simple, effective, and affordable.</strong></p>
            </div>
            <hr />
            <div className="w-50 mx-auto">
                <Image src="images/LandingImage.png" fluid alt="..." />
            </div>
            <hr />
            <div className='d-flex align-items-center flex-column justify-content-evenly'>
                <div><h2>Patients...</h2></div>
                <div className='row'>
                    <div className='col'>
                        <Image width={60} height={60} alt="" src="images/blood-pressure-gauge.png" fluid />
                    </div>
                    <div className='col'>
                        <Image width={60} height={60} alt="" src="images/glucose.png" />
                    </div>
                </div>
                <div>
                    <p>We make it simple for you to use RPM as prescribed by your care team.</p>
                    <p>We ship the equipment to you and get you ready to start monitoring your vitals in no time.</p>
                    <p>We store your data in Amazon's secure cloud so you can review it anytime and send it to your care team.</p>
                </div>
            </div>
            <hr />
            <div className='d-flex align-items-center flex-column justify-content-evenly'>
                <div><h2>Providers...</h2></div>
                <div className='row'>
                    <div className='col'>
                        <Image width={60} height={60} alt="" src="images/cloud-storage.png" />
                    </div>
                    <div className='col'>
                        <Image width={60} height={60} alt="" src="images/data-integration.png" />
                    </div>
                    <div className='col'>
                        <Image width={60} height={60} alt="" src="images/medical-record.png" />
                    </div>
                </div>
                <div>
                    <p>We simplify your workflow to order RPM for your patient.</p>
                    <p>We educate your patients in the proper use of preventative or chronic-care RPM devices.</p>
                    <p>We store patient data in Amazon's <a href='https://aws.amazon.com/compliance/hipaa-compliance/' target="_blank" rel="noreferrer">HIPAA-compliant</a> cloud.</p>
                    <p>We can integrate data you approve directly into your Electronic Medical Records (EMR) system.</p>
                </div>
            </div>
            <hr />
            <Services />
            <hr />
            <Support />
        </div >
    )
}
export default Home;