import * as React from 'react';
import { useCookies } from 'react-cookie';
import { Image } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.css';
import Home from './components/Home';
import Services from './components/Services';
import Support from './components/Support';
import SignUp from './components/SignUp';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Footer from './components/Footer';
import { Route, Routes } from "react-router-dom";
// This component redirects to an external URL when it's rendered
function Redirect() {
  React.useEffect(() => {
    window.location.href = 'https://device-enroll.d2axma31eza1rk.amplifyapp.com';
  }, []);
  
  return null;  // This component doesn't render anything
}
function App() {
  const [cookies, setCookie] = useCookies(['user']);
  const onCookieChange = (user) => {
    setCookie('Name', user.Name, { path: '/' });
    setCookie('Image', user.Image, { path: '/' });
    setCookie('Email', user.Email, { path: '/' });
  }
  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <Image src="images/avniLogo.png" width="128" height="66" alt="Avni Healthcare" />
          </a>
          <h3>{cookies.user}</h3>
          <div className='d-flex flex-row mb-3 justify-content-evenly'>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li >
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-house-heart-fill"></i>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="/Services">Services</a>
                </li>
                <li>
                  <a className="nav-link" href="/telemed">Telemed</a>
                </li>
                <li>
                  <a className="nav-link" href="/Support">Support</a>
                </li>
                {/* <li>
                  <a className="nav-link" href="/SignUp">Sign Up</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {cookies.Name &&
        <div className="row justify-content-center align-items-center mb-2">
          <h1>{cookies.Name}</h1>
        </div>
      }
      <div className="main">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/Support" element={<Support />} />
          <Route exact path="/SignUp" element={<SignUp/>} />
          <Route exact path="/Privacy" element={<Privacy />} />
          <Route exact path="/Terms" element={<Terms />} />
          <Route exact path="/telemed" element={<Redirect />} />
        </Routes>
      </div>
      {/* <footer className="footer mt-auto bg-light">
        <div className="row justify-content-center align-items-center">
          &copy; {new Date().getFullYear()} Avni Healthcare, Inc.
        </div>
      </footer> */}
      <Footer />
    </div>

  );
}

export default App;
