import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <footer className="footer mt-auto bg-light">
            <Row className="justify-content-between align-items-center">
                <Col xs={6} className="text-left">
                    &copy; {new Date().getFullYear()} Avni Healthcare, Inc.
                </Col>
                <Col xs={6} className="text-right">
                    <a href="mailto:care@avnihealth.com" title="Contact Us">
                        <FontAwesomeIcon icon={faEnvelope} /> Contact Us
                    </a>
                </Col>
            </Row>
        </footer>
    );
}

export default Footer;
