import * as React from 'react';

const Support = () => {
    const [faqOpen, setFaqOpen] = React.useState("");
    const handleFAQOpen = (event, item) => {
        if (faqOpen === item) {
            item = "";
        }
        setFaqOpen(item);
    }
    return (
        <div className='d-flex align-items-center flex-column justify-content-evenly'>
            <div><h2>Questions...</h2></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-patch-question-fill" viewBox="0 0 16 16">
                <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627z" />
            </svg>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="1" onClick={(event) => handleFAQOpen(event, "1")}> <strong>What is RPM?</strong></div>
                {faqOpen === "1" && <div><p>Remote Patient Monitoring, or RPM, is a system of digital technologies operated by patients to collect their health data with capabilities to electronically transmit the data securely to their care teams in a different location for the purpose of assessment, care recommendations, and intervention, if needed.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark" name="2" onClick={(event) => handleFAQOpen(event, "2")}> <strong>What health data does RPM collect?‍</strong></div>
                {faqOpen === "2" && <div><p>RPM devices collect blood pressure, weight, pulse, pulse oximetry, respirations, respiratory flow rates and blood sugar.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="3" onClick={(event) => handleFAQOpen(event, "3")}> <strong>As a patient, how do I get RPM?‍</strong></div>
                {faqOpen === "3" && <div><p>During an in-office or a telemedicine visit, your care team may prescribe use of RPM to monitor your vitals between visits.  You may also purchase RPM over the counter at your local pharmacy, but your should seek your care team’s advice as some devices are only available by prescription.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="4" onClick={(event) => handleFAQOpen(event, "4")}> <strong>Does Medicare pay for RPM?</strong></div>
                {faqOpen === "4" && <div><p>Depending on the coverages you elected, there may be a monthly copay with Medicare picking up the majority of the cost.  Your should consult your care team and your insurance carrier for more details.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="5" onClick={(event) => handleFAQOpen(event, "5")}> <strong>Does Private Insurance pay for RPM?</strong></div>
                {faqOpen === "5" && <div><p>More and more private insurance providers are providing RPM benefits.  You should consult with your carrier for more details.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="6" onClick={(event) => handleFAQOpen(event, "6")}> <strong>What are Electronic Medial Records (EMR) and Clinical Systems?</strong></div>
                {faqOpen === "6" && <div><p>All patient records are stored and maintained in an EMR, e.g., Epic&trade;, Cerner&trade;, and eClinicalWorks&trade;. A Clinical System is used by care teams to manage their practice and to access and update their patients’ records in the EMRs.  While Clinical Systems are often provided by EMRs, there are others that integrate into one or more EMRs as required by the practice.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="7" onClick={(event) => handleFAQOpen(event, "7")}> <strong>Can I access my (patient) health records and how?</strong></div>
                {faqOpen === "7" && <div><p>Yes.  More information is available on <a href="https://www.hhs.gov" target="_blank" rel="noreferrer">Health and Human Services (HHS) website.</a>  Ask your care team; they may be able to help you.</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="8" onClick={(event) => handleFAQOpen(event, "8")}> <strong>Who has access to my health data and is it secure?</strong></div>
                {faqOpen === "8" && <div><p>By law, your health data is secure and is only available to those entities you specifically authorize, e.g., care teams, insurance carriers. More information is available on <a href="https://www.hhs.gov" target="_blank" rel="noreferrer">Health and Human Services (HHS) website</a></p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="9" onClick={(event) => handleFAQOpen(event, "9")}> <strong>Does Avni have access to my health data?</strong></div>
                {faqOpen === "9" && <div><p>Your care team will provide you details of Avni’s role in RPM implementation and support.  With RPM prescription, you will be requested to approve sharing of your name, preferred contact method and details, i.e., phone call, e-mail, text message, e-mail address, and RPM prescription with Avni so that Avni can help you setup the RPM system. During the setup and training, Avni will guide you through the operation of the RPM device(s) and will require a one-time authorization—duration of training—to confirm proper operation of the RPM.  This is the only time Avni will have access to the data collected by the RPM device(s).</p></div>}
            </div>
            <div>
                <div className="btn btn-link text-dark hangingindent" name="10" onClick={(event) => handleFAQOpen(event, "10")}> <strong>What patient information does Avni store in its system?</strong></div>
                {faqOpen === "10" && <div><p>To get you ready to use prescribed RPM and with your consent, your care team provides Avni with your name, phone number, and email address so we may contact you.  With consent from you and your care team, our systems can monitor your data and alert us for any scheduled and missed readings or anamolies.  To learn more about your rights to privacy, please visit <a href="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html" target="_blank" rel="noreferrer">Health and Human Services (HHS)</a> website.</p></div>}
            </div>
        </div>

    )
}
export default Support;